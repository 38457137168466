import axios from "axios";
import React, { useState } from "react";
import MainLayout from "../components/MainLayout";
import { toast } from "react-hot-toast";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        from_name: name,
        from_email: email,
        to_name: "Web Wizard",
        message: message,
      },
    };

    try {
      const res = await axios.post(
        "https://api.emailjs.com/api/v1.0/email/send",
        data
      );
      setName("");
      setEmail("");
      setMessage("");
      toast.success("Message sent successfully!");
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <MainLayout>
      <div className="bg-primary">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/2">
            <img
              src="https://mycereracoffeebucket.s3.eu-north-1.amazonaws.com/undrawcontact.13c80369250d8f0aff509318d9d484eb.svg"
              alt=""
              className="w-full h-[300px] md:h-[400px]"
            />
          </div>
          <div className="md:w-1/2 p-5 md:px-20 flex flex-col justify-center md:justify-start">
            <h3 className="text-lg md:text-xl text-gray-200 mb-5 font-bold">
              Contact us for more information !
            </h3>
            <form onSubmit={handleSubmit} className="flex flex-col w-full ">
              <input
                type="text"
                placeholder="Your Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="mb-4 p-2 border-1 border-gray-200 shadow-sm shadow-gray-300 bg-gray-200 placeholder:text-gray-700 rounded-lg"
              />
              <input
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-4 p-2 border-1 border-gray-200 shadow-sm shadow-gray-300 bg-gray-200 placeholder:text-gray-700 rounded-lg"
              />
              <textarea
                cols="30"
                rows="10"
                placeholder="Your Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="mb-4 p-2 h-[200px] border-1 border-gray-200 shadow-sm shadow-gray-300 bg-gray-200 placeholder:text-gray-700 rounded-lg"
              ></textarea>
              <button
                type="submit"
                className="bg-buttons w-full text-white py-2 px-4 rounded hover:bg-orange-400 self-center md:self-start"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default ContactForm;
