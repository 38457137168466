import React from "react";
import MeetOurTeamComponent from "../components/MeetOurTeamComponent";
import AwardsComponent from "../components/AwardsComponent";
import { Link } from "react-router-dom";
import MainLayout from "../components/MainLayout";
import coffee from "../assets/bag1.jpg";
import coffee2 from "../assets/onebean.jpg";

const About = () => {
  return (
    <>
      <MainLayout>
        <div className="bg-primary">
          <div className="container mx-auto px-4 md:py-8 md:px-8 rounded-lg flex flex-col lg:flex-row justify-center items-center md:justify-between relative z-20">
            <div className="flex flex-col md:flex-row md:items-center mt-10 md:mt-0 lg:mt-10">
              <div className="md:w-2/3 lg:-mt-[80px]">
                <h1 className="text-2xl font-bold mb-3  text-gray-100">
                  Get to know us more
                </h1>
                <p className="text-sm md:text-md leading-relaxed lg:mb-4 text-gray-300 pr-3">
                  Cerera Coffee Import Export Company is dedicated to sourcing
                  the finest coffee beans from around the world and delivering
                  them to coffee lovers everywhere.
                </p>
                <div className="bg-darkbrown-light px-4 py-8 rounded-sm mt-5 md:mt-0">
                  <div className="flex flex-row items-start justify-start w-full z-20">
                    <p className="flex text-3xl md:text-5xl leading-tight text-darkbrown-eerie font-bold font-pacifico">
                      Cerera Coffee
                    </p>
                    <div className="w-20 h-20 -mt-6">
                      <img src={coffee2} alt="" className="w-full" />
                    </div>
                  </div>
                  <hr className="my-4 border-gray-600 sm:mx-auto dark:border-gray-700" />
                  <p className="text-sm font-bold">
                    Experience the unparalleled taste of Cerera Coffee - a blend
                    crafted to perfection
                  </p>
                  <div className="flex space-x-3 mt-3">
                    <button className="bg-darkbrown-eerie py-2 px-4 rounded-full"></button>
                    <button className="bg-darkbrown-eerie py-2 px-4 rounded-full"></button>
                    <button className="bg-darkbrown-eerie py-2 px-4 rounded-full"></button>
                  </div>
                </div>
              </div>

              <div className="hidden md:block md:w-1/2 relative mt-10 md:mt-0 z-10 lg:-mt-[80px]">
                <img
                  src={coffee}
                  className="mx-auto lg:mx-0 w-full lg:w-auto h-[500px] object-cover rounded-md"
                  alt="Coffee"
                />
              </div>
            </div>
            <div className="w-full md:w-1/2 lg:w-2/3 mt-10 md:mt-0 z-20">
              <ol className="relative border-s border-gray-200 dark:border-gray-700 md:block">
                <li className="mb-10 ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    February 2018
                  </time>
                  <h3 className="text-lg font-semibold text-gray-200 dark:text-white">
                    Cerera Coffee's Inception
                  </h3>
                  <p className="mb-4 text-sm font-normal text-gray-400 dark:text-gray-400">
                    Cerera Coffee was founded with a vision to provide
                    exceptional coffee experiences to enthusiasts worldwide.
                  </p>
                </li>
                <li className="mb-10 ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    March 2019
                  </time>
                  <h3 className="text-lg font-semibold text-gray-200 dark:text-white">
                    Expansion into Import-Export
                  </h3>
                  <p className="text-sm text-gray-400 font-normal dark:text-gray-400">
                    Cerera Coffee expanded its operations into the import-export
                    sector, sourcing premium coffee beans from renowned
                    coffee-growing regions.
                  </p>
                </li>
                <li className="ms-4">
                  <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>
                  <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                    Present
                  </time>
                  <h3 className="text-lg font-semibold text-gray-200 dark:text-white">
                    Continued Growth and Success
                  </h3>
                  <p className="text-sm font-normal text-gray-400 dark:text-gray-400">
                    Cerera Coffee has experienced significant growth,
                    establishing itself as a leading player in the import-export
                    coffee industry, delivering premium coffee beans to
                    customers worldwide.
                  </p>
                </li>
              </ol>
            </div>
          </div>

          <div className="container mx-auto px-4 py-8 md:px-8">
            <h2 className="font-bold text-2xl text-white"> About Us</h2>
            <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700" />
            <p className="text-gray-300">
              Cerera Coffee was established in the heart of coffee culture,
              where passion meets precision, in Ethiopia. Since our founding in
              2018, we've embarked on a journey to redefine the coffee
              experience for enthusiasts worldwide. Our story began with a
              shared love for exceptional coffee and a vision to deliver the
              finest beans from around the globe to your cup. With a dedicated
              team of coffee aficionados, each bringing unique expertise in
              sourcing, roasting, and crafting the perfect brew, Cerera Coffee
              has quickly risen to prominence in the industry. In 2019, we
              achieved a significant milestone, marking Milestone in our
              journey. This achievement is a testament to our unwavering
              commitment to quality, innovation, and customer satisfaction. At
              Cerera Coffee, we believe in transparency, sustainability, and
              ethical sourcing practices. We work closely with coffee farmers
              and communities, ensuring fair trade practices and supporting
              sustainable agriculture. Our name, Cerera, embodies our dedication
              to excellence and reverence for the art of coffee. Derived from
              Inspiration, Cerera represents our commitment to elevating your
              coffee experience to new heights. Join us on our mission to
              discover the world of coffee, one cup at a time. Welcome to Cerera
              Coffee.
            </p>
          </div>

          <div className="">
            <div className="relative h-full">
              <div className="container mx-auto px-4 py-8 md:px-8 relative z-20">
                <h2 className="font-bold text-2xl text-white">
                  Our Coffee Products
                </h2>
                <hr className="my-4 border-gray-400 sm:mx-auto dark:border-gray-700" />
                <div className="flex flex-col md:flex-row items-center lg:mt-10">
                  <div className="md:w-1/2 mb-4 md:mb-0 lg:mt-3 rounded-lg">
                    <img
                      src="https://mycereracoffeebucket.s3.eu-north-1.amazonaws.com/aboutus.0d224d4c096f1efe1dab.jpg"
                      alt=""
                      className="rounded-lg w-full"
                    />
                  </div>
                  <div className="md:w-1/2 md:pl-8 font-semibold">
                    <p className="text-md md:text-sm lg:text-md mb-4 pr-2 pt-10 md:pt-6 lg:pt-32 text-gray-800 md:text-gray-300 lg:text-gray-800">
                      Welcome to{" "}
                      <span className="font-semibold text-lg">
                        Cerera Coffee
                      </span>
                      , your destination for exquisite coffee experiences.
                    </p>
                    <p className="text-md  mb-7 lg:mb-7">
                      Discover our exceptional range of single-origin beans,
                      expertly roasted to perfection to unlock their full
                      potential. From rich and bold espresso blends to delicate
                      and nuanced pour-over selections, Cerera Coffee offers a
                      variety of options to suit every palate and preference.
                    </p>
                    <Link
                      to="/products"
                      className="px-6 py-3 bg-buttons text-white rounded-lg hover:bg-orange-400"
                    >
                      Learn more
                    </Link>
                  </div>
                </div>
              </div>
              <div className="absolute inset-0 bg-[#31452C]"></div>
              <div className="absolute inset-x-0 bottom-0 h-1/2 md:h-1/3 lg:h-1/2 bg-[#E4CC99]"></div>
            </div>
          </div>

          <AwardsComponent />
          <MeetOurTeamComponent />
        </div>
      </MainLayout>
    </>
  );
};

export default About;
