import React from "react";
import { Link } from "react-router-dom";

const Aboutus = () => {
  return (
    <div className="mt-5 md:mt-20">
      <div className="container mx-auto py-5 rounded-lg flex flex-col md:flex-row justify-center">
        <div className="md:w-1/2 mb-4 md:mb-0 md:-mt-[150px] rounded-lg">
          <div className="w-full md:w-[600px] md:h-[630px]">
            <img
              src="https://mycereracoffeebucket.s3.eu-north-1.amazonaws.com/coffeebeans2.84c9f7b8c945a7c131f5.jpg"
              className="mx-auto md:mx-0 w-full md:w-auto h-[300px] object-cover md:h-full rounded-md"
              alt="Coffee"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 z-20 ">
          <div className="hidden md:block md:pl-3">
            <div
              className="bg-darkbrown-light py-3 pl-16 w-[370px]"
              style={{
                borderTopRightRadius: "80px",
                borderTopLeftRadius: "100px",
                borderBottomRightRadius: "80px",
                overflow: "hidden",
              }}
            >
              <h1 className="-ml-1 text-3xl font-pacifico text-darkbrown-eerie font-bold mb-2">
                Cerera Coffee
              </h1>
              <hr className="border-gray-600 sm:mx-auto dark:border-gray-700 mb-3" />
              <p className="text-sm text-darkbrown-eerie font-bold mb-2">
                {" "}
                Try One Of The Best Coffee From Ethiopia
              </p>
              <button className="bg-darkbrown-eerie py-1 px-4 rounded-full text-white text-sm">
                Try it for yourself
              </button>
            </div>
          </div>
          <div className="bg-darkbrown-light md:bg-transparent py-10 md:py-5 px-4 md:pl-3 lg:mt-[50px] rounded-md">
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold mb-3 md:mb-5 text-gray-800 md:text-gray-100">
              Learn More About Us & Our Coffee
            </h1>
            <p className="text-md md:text-sm lg:text-lg leading-relaxed mb-5 lg:mb-8 text-gray-600 md:text-gray-300 md:pr-3">
              Discover the story behind Cerera Coffee and our passion for
              exceptional coffee. We're dedicated to sourcing the finest beans
              from across the globe while championing sustainability and ethical
              practices. Join us in exploring the diverse flavors and aromas of
              our carefully selected coffee offerings.
            </p>
            <div className="flex flex-col lg:flex-row gap-3">
              <Link
                to="/contact"
                className="px-6 py-3 bg-buttons text-white rounded-lg hover:bg-orange-400"
              >
                Contact us
              </Link>
              <Link
                to="/about"
                className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              >
                Learn more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
